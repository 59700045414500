<template>
  <div class="card" :class="[show ? 'show' : 'hide']" >
    <div class="card-header w-100 d-flex">
      <h2 class="card-title font-weight-bolder" ref="headerSlot">
        <slot name="header">

        </slot>
      </h2>
      <div class="ml-auto">
        <i class="fas fa-angle-left" @click="toggleShow()" :class="show ? 'arrow-90' : 'arrow-0 collapsed'"
           :aria-expanded="show ? 'true' : 'false'"
           aria-controls="collapse-4"></i>
      </div>
    </div>
    <b-collapse id="collapse-4" v-model="show">
      <div class="card-body" v-if="isReady">
        <slot name="content">

        </slot>
      </div>
      <div class="spinner-wrapper" v-else>
        <b-spinner></b-spinner>
      </div>
      <div class="card-footer" v-if="isFooterSlotUsed">
        <slot name="footer">

        </slot>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import {BSpinner} from "bootstrap-vue";

export default {
  components: { BSpinner },
  name: "PageCard",
  props: {
    isReady: { default: true, type: Boolean}
  },
  mounted() {
    this.show = JSON.parse(localStorage.getItem(this.$route.name + this.$refs.headerSlot.textContent)) ?? true;
  },
  data() {
    return {
      show: true
    }
  },
  methods: {
    toggleShow(){
      this.show = !this.show;
      localStorage.setItem(this.$route.name + this.$refs.headerSlot.textContent, this.show);
    }
  },
  computed: {
    isFooterSlotUsed(){
      return this.$slots.footer;
    }
  }
}
</script>

<style scoped>
  .card {
    transition: all 1s;
    height:auto;
  }
  .arrow-0 {
    transform: rotate(0deg);
  }
  .arrow-90 {
    transform: rotate(-90deg);
  }
  .fa-angle-left {
    cursor: pointer;
    transition:transform 250ms ease-in-out;
  }
  .card-footer{
    margin-top: -10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }
</style>
