<template>
  <div class="content-header pt-0 mb-2">
    <div class="row d-flex align-items-center">
      <div class="col-8">
        <h1 class="m-0 text-dark">
          <slot>

          </slot>
        </h1>
      </div>
      <div class="col-4 d-flex justify-content-end align-items-center">
        <slot name="right">

        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageHeader'
}
</script>

<style scoped>
h1 {
  color: #343a40;
}
</style>
