<template>
  <b-form-group :label="label">
    <b-form-input :placeholder="placeholder"
                  v-model="filter"
                  @input="applyFilter"
                  @keypress="restrictInput ? isRestricted($event) : null"
                  trim>
    </b-form-input>
  </b-form-group>
</template>

<script>
export default {
  name: "Input",
  props: {
    label: {},
    placeholder: {},
    filterName: {},
    filterQuery: {},
    restrictInput: {},
    restrictionType: {},
    filterOperator: {
      default: 'eq'
    },
    storeData: {},
    defaultFilter: null
  },
  data() {
    return {
      filter: null,
    };
  },
  mounted() {
    if(this.defaultFilter) {
      this.filter = this.defaultFilter;
      this.applyFilter();
    }

    this.$root.$on('resetFilters', () => {
      this.filter = null;
    })

    this.checkStoreData();
  },
  methods: {
    checkStoreData() {

      // eslint-disable-next-line no-prototype-builtins
      if (this.storeData && this.storeData.hasOwnProperty(this.filterName)) {
        if (this.storeData[this.filterName] !== null) {
          this.filter = this.storeData[this.filterName];
          this.applyFilter();
        }
      }
    },
    applyFilter() {
      this.$emit('filterValueChanged', this.filter);

      if (this.filter !== '') {
        this.filterQuery[this.filterName] = '&filters[' + this.filterName + '][0][value]=' + this.format(this.filter) + '&filters[' + this.filterName + '][0][operator]=' + this.filterOperator;
        this.toStore();
      } else {
        this.$emit('store', {filterName: this.filterName, value: null});
        delete this.filterQuery[this.filterName];
      }
    },
    toStore() {
      this.$emit('store', {filterName: this.filterName, value: this.filter});
    },
    format(data) {
      if (this.restrictInput && this.restrictionType === 'decimals') {
        return data.toString().replace(',', '.');
      }
      return encodeURIComponent(data);
    },
    isRestricted: function(e) {
      e = (e) ? e : window.event;
      let charCode = (e.which) ? e.which : e.keyCode;
      switch (this.restrictionType) {
        case 'numbers':
          // only 0-9 are allowed
          if (charCode < 48 || charCode > 57) {
            e.preventDefault();
          } else {
            return true;
          }
          break;
        case 'decimals':
          // 0-9 and . are allowed
          if (charCode !== 44 && charCode !== 46 && (charCode < 48 || charCode > 57)) {
            e.preventDefault();
          } else {
            return true;
          }
          break;
        case 'letters':
          // a-z and A-Z are allowed
          if (charCode < 65 || (charCode > 90 && charCode < 97) || charCode > 122) {
            e.preventDefault();
          } else {
            return true;
          }
          break;
        default:
          return true; // no restriction after all
      }
    }
  }
}
</script>
